import * as React from 'react';
import Box from '@mui/material/Box';
import { useMediaQuery } from '@mui/material';
import { DataGridPro, GridToolbarContainer, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import Chip from '@mui/material/Chip';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import fetchDataAndGeneratePdf from '../../../utils/jspdf';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import roundToQuarter from '../../../utils/scale';
import { useNavigate } from 'react-router-dom';
import { frFR } from '@mui/x-data-grid/locales';
import { useTheme } from "@mui/material/styles";
import CircularProgress from '@mui/material/CircularProgress';
import { createSvgIcon } from '@mui/material/utils';
import { fetchReportImport, ChangeAllReportsStatus, BatchCreateReports, UpdateContracts } from '../../../services/fetch';
import jwtDecode from 'jwt-decode';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, TextField } from '@mui/material';
import { da } from 'date-fns/locale';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';


const handleTotal = (start, end, mealTime) => {
  const newStart = dayjs(start);
  const newEnd = dayjs(end);
  const tempNewTotal = newEnd.diff(newStart, 'hour', true);
  if (isNaN(tempNewTotal)) {
    return 0;
  } else {
    if (tempNewTotal < 0) {
      const newTotal = 24 + tempNewTotal - Number(mealTime);
      return roundToQuarter(newTotal);
    } else {
      const newTotal = tempNewTotal - Number(mealTime);
      return roundToQuarter(newTotal);
    }
  }
}

const handleBigTotal = (activities) => {
  let total = 0;
  if (activities) {
    activities.forEach((activity) => {
      total += handleTotal(activity.start, activity.end, activity.meal_time); 
    });
  }
  return total.toFixed(2);
}

function addIdToDicts(listOfDicts) {
  let idCounter = 1;
  for (const dict of listOfDicts) {
    dict.id = idCounter++;
    if (typeof dict['start'] === 'string') {
      dict['start'] = new Date(dict['start'] + 'T00:00');
    }
    if (typeof dict['end'] === 'string') {
      dict['end'] = new Date(dict['end'] + 'T00:00');
    }
    
    dict['total'] = parseFloat(handleBigTotal(dict['activities']));
    dict['expense_number'] = dict['expenses'].length;
  }
}

function rearrangeName(fullName) {
  const parts = fullName.split(' ');
  if (parts.length >= 2) {
    const lastName = parts[parts.length - 1];
    const firstName = parts.slice(0, parts.length - 1).join(' ');
    return `${lastName}, ${firstName}`;
  } else {
    return fullName;
  }
}


export default function FilterList(props) {

  const navigate = useNavigate();

  const reports = props.reports;
  addIdToDicts(reports);

  const [token, setToken] = React.useState(localStorage.getItem('token'));
  const [role, setRole] = React.useState(jwtDecode(token).sub.role);

  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialog2, setOpenDialog2] = React.useState(false);
  const [openDialog3, setOpenDialog3] = React.useState(false);

  const [file, setFile] = React.useState(null);

  const handleSetFile = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleRemoveFile = () => {
    setFile(null);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog2 = () => {
    setOpenDialog2(true);
  };

  const handleCloseDialog2 = () => {
    setOpenDialog2(false);
  };

  const handleOpenDialog3 = () => {
    setOpenDialog3(true);
  };

  const handleCloseDialog3 = () => {
    setOpenDialog3(false);
    handleRemoveFile(null);
  };

  const handleClick = (params) => {
    if (params.field !== 'action') {
      navigate(`/timesheets/${params.row.report_id}`);
    }
  };

  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    timeZone: 'UTC'
  };

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.up("xs"));
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const xl = useMediaQuery(theme.breakpoints.up("xl"));

  const defaultUserNameRenderCell = (params) => (
    <Box>
      {params.row.user_name}
    </Box>
  );

  const defaultStatusRenderCell = (params) => {
    const status = params.value;
    let color;
    
    switch(status) {
      case 'En cours':
        color = 'success';
        break;
      case 'En traitement':
        color = 'warning';
        break;
      case 'Complété':
        color = 'primary';
        break;
      case 'Archivé':
        color = 'error';
        break;
      default:
        color = 'default';
    }
    
    return <Chip label={status} color={color} variant="outlined" />;
  }

  const xsUserNameRenderCell = (params) => (
      <Box>
        <Box>{params.row.user_name}</Box>
        <Box sx={{ fontSize: 12 }}>{params.row.start.toLocaleDateString("fr-FR", options)}</Box>
      </Box>
  );

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  

  

  const xsStatusRenderCell = (params) => {
    const status = params.value;
    let color;
    
    switch(status) {
      case 'En cours':
        color = 'success';
        break;
      case 'En traitement':
        color = 'warning';
        break;
      case 'Complété':
        color = 'primary';
        break;
      case 'Archivé':
        color = 'error';
        break;
      default:
        color = 'default';
    }
    
    return <Chip label={status} color={color} variant="outlined" size="small" sx={{ fontSize: 12 }} />;
  }

  const userNameRenderCell = md ?  defaultUserNameRenderCell : xsUserNameRenderCell;
  const statusRenderCell = md ? defaultStatusRenderCell : xsStatusRenderCell;

  let columnVisibility;
  let leftPadding = 0;
  let rightPadding = 0;
  let raidusBorder = 0;

  if (xl) {

    columnVisibility = {};
    leftPadding = 25
    rightPadding = 25
    raidusBorder = 0

  } else if (lg) {

    columnVisibility = {
      expense_number: false,
      sector: false
    };

  } else if (md) {

    columnVisibility = {
      expense_number: false,
      sector: false,
      action: false,
      user_number: false
    };

  } else if (sm) {

    columnVisibility = {
      expense_number: false,
      sector: false,
      action: false,
      start: false,
      end: false,
      approved: false,
      user_number: false
    };

  } else if (xs) {

    columnVisibility = {
      expense_number: false,
      sector: false,
      action: false,
      start: false,
      end: false,
      approved: false,
      user_number: false
    };


  } else {

    columnVisibility = {
      expense_number: false,
      sector: false,
      user_number: false
    };

  }

  let columns = [
    {
      field: 'user_number',
      headerName: '#',
      type: 'string',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <strong>
          {'#'}
        </strong>
      ),
      renderCell: (params) => {
        return (
          <Chip
            label={params.row.user_number}
            size="small"
            style={{ marginTop: '5px' }}
          />
        );
      }
    },
    {
      field: 'user_name',
      headerName: 'Nom',
      type: 'string',
      flex: 1.25,
      renderHeader: () => (
        <strong>
          {'Nom'}
        </strong>
      ),
      renderCell: userNameRenderCell
    },
    {
      field: 'start',
      headerName: 'Début',
      type: 'date',
      flex: 1,
      renderHeader: () => (
        <strong>
          {'Début'}
        </strong>
      ),
    },
    {
      field: 'end',
      headerName: 'Fin',
      type: 'date',
      flex: 1,
      renderHeader: () => (
        <strong>
          {'Fin'}
        </strong>
      ),
    },
    {
      field: 'status',
      headerName: 'Statut',
      type: 'singleSelect',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      valueOptions: [
        'En cours',
        'En traitement',
        'Complété',
        'Archivé'
      ],
      renderHeader: () => (
        <strong>
          {'Statut'}
        </strong>
      ),
      renderCell: statusRenderCell
    },
    {
      field: 'total',
      headerName: 'Total',
      type: 'number',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <strong>
          {'Total'}
        </strong>
      ),
    },
    {
      field: 'approved',
      headerName: 'Approuvé',
      type: 'boolean',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderHeader: () => (
        <strong>
          {'Approuvé'}
        </strong>
      ),
    },
    {
      field: 'sector',
      headerName: 'Secteur',
      type: 'string',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderHeader: () => (
        <strong>
          {'Secteur'}
        </strong>
      ),
    },
    {
      field: 'expense_number',
      headerName: 'Dépenses',
      type: 'number',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <strong>
          {'Dépenses'}
        </strong>
      ),
    },
    {
      field: 'action',
      headerName: 'PDF',
      type: 'actions',
      flex: 1,
      renderHeader: () => (
        <strong>
          {'PDF'}
        </strong>
      ),
      renderCell: (params) => {
        const handleClick = () => {
          fetchDataAndGeneratePdf(params.row.report_id)
        };
  
        return (
          <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              onClick={handleClick}
            >
              <PictureAsPdfIcon sx={{ color: '#343434' }} />
            </IconButton>
        );
      }
    }
  ]

  

  

  function CustomToolbar() {

    const ExportIcon = createSvgIcon(
      <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
      'SaveAlt',
    );

    const handleExport = async () => {
      try {
        if (role === 'admin') {
          const exportData = await fetchReportImport();
          const data = exportData.data;
          const filename = exportData.filename;

          // Create a URL for the blob
          const url = window.URL.createObjectURL(new Blob([data]));

          // Create a link element
          const a = document.createElement('a');
          a.href = url;
          a.download = filename; // This will suggest the filename for the download
          document.body.appendChild(a);
          a.click(); // Programmatically click the link to trigger the download
          document.body.removeChild(a); // Clean up the DOM
          window.URL.revokeObjectURL(url); // Free up memory
        } else {
          console.log('raise exc')
        }
        

      } catch (error) {
        console.error('Error fetching report data:', error);
      }
    };

    const handleCloseStatus = async () => {
      try {
        if (role === 'admin') {
          handleCloseDialog();
          const data = await ChangeAllReportsStatus();
        } else {
          console.log('raise exc')
        }
        
      } catch (error) {
        console.error('Error fetching report data:', error);
      }
    };

    const handleNewReports = async () => {
      try {
        if (role === 'admin') {
          handleCloseDialog2();
          const data = await BatchCreateReports();
        } else {
          console.log('raise exc')
        }
        
      } catch (error) {
        console.error('Error fetching report data:', error);
      }
    };

    const handleUpdateContracts = async () => {
      try {
        if (role === 'admin') {
          handleCloseDialog3();
          const data = await UpdateContracts(file);
        } else {
          console.log('raise exc')
        }
      } catch (error) {
        console.error('Error fetching report data:', error);
      }
    };


    const buttonBaseProps = {
      color: 'primary',
      size: 'small',
      startIcon: <ExportIcon />,
    };

    const buttonBaseProps2 = {
      color: 'primary',
      size: 'small',
      startIcon: <CheckCircleOutlineIcon />,
    };

    const buttonBaseProps3 = {
      color: 'primary',
      size: 'small',
      startIcon: <NoteAddIcon />,
    };

    const buttonBaseProps4 = {
      color: 'primary',
      size: 'small',
      startIcon: <EngineeringIcon />,
    };


    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        {role === 'admin' && (
          <>
          <Button
            {...buttonBaseProps}
            onClick={() => handleExport()}
          >
            Exporter la paie en cours
          </Button>
          <Button
            {...buttonBaseProps2}
            onClick={() => handleOpenDialog()}
          >
            Changer le statut
          </Button>
          <Button
            {...buttonBaseProps3}
            onClick={() => handleOpenDialog2()}
          >
            Créer les rapports de la semaine
          </Button>
          <Button
            {...buttonBaseProps4}
            onClick={() => handleOpenDialog3()}
          >
            Importer des contrats
          </Button>
          <Dialog open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle>Êtes-vous certain de vouloir fermer les rapports en cours?</DialogTitle>
            <DialogActions>
              <Button autoFocus onClick={handleCloseDialog}>
                Annuler
              </Button>
              <Button onClick={handleCloseStatus} autoFocus>
                Continuer
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={openDialog2} onClose={handleCloseDialog2}>
            <DialogTitle>Êtes-vous certain de vouloir créer les rapports de la semaine en cours?</DialogTitle>
            <DialogActions>
              <Button autoFocus onClick={handleCloseDialog2}>
                Annuler
              </Button>
              <Button onClick={handleNewReports} autoFocus>
                Continuer
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={openDialog3} onClose={handleCloseDialog3}>
            <DialogTitle>Importer le fichier de contrat (CSV)</DialogTitle>
            <Box sx={{ paddingLeft: 2, paddingRight: 2, display: 'flex', justifyContent: 'center' }}>
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
            >
              Upload files
              <VisuallyHiddenInput
                type="file"
                onChange={handleSetFile}
                multiple
              />
            </Button>
            </Box>
            {file && (
              <Box sx={{ paddingLeft: 2, paddingRight: 2, marginTop: 2 }}>
                <TextField
                  fullWidth
                  label="Selected File"
                  value={file.name}
                  variant="outlined"
                  InputProps={{
                    readOnly: true, // Make the field read-only
                  }}
                />
              </Box>
            )}
            <DialogActions>
              <Button autoFocus onClick={handleCloseDialog3}>
                Annuler
              </Button>
              <Button autoFocus onClick={handleUpdateContracts}>
                Continuer
              </Button>
            </DialogActions>
          </Dialog>
          </>
        )}
      <Box style={{ flex: 1 }} />
      <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  }
  

  return (
    
    <Box sx={{ backgroundColor: '#f0f0f0', paddingTop: 0, paddingBottom: 5, paddingLeft: leftPadding, paddingRight: rightPadding }}>
        <Box sx={{ backgroundColor: '#FFFFFF', borderRadius: raidusBorder }}>
          {reports.length === 0 ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <CircularProgress />
            </Box>
          ) : (
            <DataGridPro
            pagination
            paginationPageSize={100}
            disableColumnSelector
            disableDensitySelector
            columnVisibilityModel={columnVisibility}
            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
            columns={columns}
            rows={reports}
            slots={{ toolbar: CustomToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            sx={{ borderRadius: raidusBorder }}
            onCellClick={handleClick}
          />

          )}
          
          
        </Box>
    </Box>
  );
}
